var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _c("div", { staticClass: "container" }, [
      _c("h3", [_vm._v("特定商取引法の表示")]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "components-box col-lg-8" }, [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _vm._m(4),
          _c("div", { staticClass: "table-row" }, [
            _c("div", { staticClass: "table-header" }, [
              _vm._v("お問い合わせ")
            ]),
            _c(
              "div",
              { staticClass: "table-detail" },
              [
                _c("router-link", { attrs: { to: "/inquiry_form/input" } }, [
                  _vm._v("こちらの窓口")
                ]),
                _vm._v("よりお願い致します。")
              ],
              1
            )
          ]),
          _vm._m(5),
          _vm._m(6),
          _vm._m(7),
          _vm._m(8),
          _vm._m(9),
          _vm._m(10),
          _vm._m(11)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("事業者名")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" 株式会社ロビンソン"),
        _c("br"),
        _vm._v("(英語名: Robinson Co., Ltd.)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("代表者")]),
      _c("div", { staticClass: "table-detail" }, [_vm._v(" 藤井 真琴")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("URL")]),
      _c("div", { staticClass: "table-detail" }, [
        _c(
          "a",
          { attrs: { href: "https://www.robinsons.co.jp/", target: "_blank" } },
          [_vm._v("https://www.robinsons.co.jp")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("所在地")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" 〒154-0005"),
        _c("br"),
        _vm._v("東京都世田谷区三宿1-13-1"),
        _c("br"),
        _vm._v("東映三宿ビル 4階")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("TEL/FAX")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" 03-5431-3955（代表）"),
        _c("br"),
        _vm._v("03-5431-3956（FAX）")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("利用料")]),
      _c("div", { staticClass: "table-detail" }, [_vm._v(" 月額300円（税込）")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [
        _vm._v("商品代金以外の付帯費用")
      ]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" クレジットカード手数料"),
        _c("br"),
        _vm._v(
          "キャリア決済手数料 その他、サイトの閲覧、お問い合わせ等の際の電子メールの送受信時などに、所定の通信料が発生します"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("代金の支払時期")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" ご利用のカード会社またはキャリアごとに異なります。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("代金の支払方法")]),
      _c("div", { staticClass: "table-detail" }, [
        _c("div", { staticClass: "detail-header" }, [
          _vm._v("クレジットカード決済")
        ]),
        _c("div", { staticClass: "detail-text" }, [
          _vm._v("（Visa/Mastercard/JCB/AMEX/DINERS）")
        ]),
        _c("div", { staticClass: "detail-header" }, [
          _vm._v("携帯キャリア決済")
        ]),
        _c("div", { staticClass: "detail-text" }, [
          _vm._v("（SoftBank docomo）")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [
        _vm._v("商品等の引き渡し時期")
      ]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(
          " 有料プランの登録及び決済手続きが完了後すぐに、有料サービスが利用可能となります。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("返品の可否と条件")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(" 電子商品としての性質上、返品には応じられません。 ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-header" }, [_vm._v("動作ブラウザ")]),
      _c("div", { staticClass: "table-detail" }, [
        _vm._v(
          " Safari 12以上およびMicrosoft Edge・Google Chrome・Firefoxの各最新版"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }